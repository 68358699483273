<template>
  <b-card-code
    no-body
    title="List Referral"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-row mb-1 justify-content-start">
            <div class="col-lg-4 mb-1 mb-lg-0 align-self-end justify-content-end">
              <input
                v-model="filter.keyword"
                type="text"
                placeholder="Search"
                class="form-control form-control-sm"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <ListReferral
      :result="result"
      :is-loading="isLoading"
      :get-list-referral-data="getData"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

import ListReferral from '@/components/Referral/ListReferral.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `List Referral`
  },
  components: {
    BCardCode,
    ListReferral,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  data() {
    return {
      result: {},
      filter: {
        keyword: '',
      },
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      const userType = 'normal'
      const queryParams = this.filter
      queryParams.page = page
      queryParams.user_type = userType
      queryParams.is_referred = 1

      this.$http.get('/admin/users', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
