<template>
  <div>
    <div class="table-responsive">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Name</th>
            <th>Registered At</th>
            <th>Refered By</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>
              <router-link :to="`/user-detail/${item.uuid}`">
                {{ item.name }}
              </router-link><br />
              <small>{{ item.email }}</small>
            </td>
            <td>
              {{ item.registered_at }}
            </td>
            <td v-if="item.referedBy">
              <router-link :to="`/user-detail/${item.referedBy.uuid}`">
                {{ item.referedBy.name }}
              </router-link><br />
              <small>{{ item.referedBy.email }}</small>
            </td>
            <td>
              <b-dropdown
                variant="info"
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('show users')"
                  @click="goToDetail(item.uuid)"
                >
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  Detail User
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('show users')"
                  @click="goToDetail(item.referedBy.uuid)"
                >
                  <feather-icon
                    icon="UsersIcon"
                    class="mr-75"
                  />
                  Detail Invitee
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getListReferralData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    getListReferralData: {
      type: Function,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  methods: {
    goToDetail(id) {
      this.$router.push({ name: 'users.show', params: { id } })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
